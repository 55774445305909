import gsap from 'gsap';

export default class Sticker {
    constructor(card, image, ratio, group, parentIndex, index, isSelected) {
        this.card = card;
        this.container = this.card.querySelector('.drag-section');
        this.image = image;
        this.ratio = ratio;
        this.stickerGroup = group;
        this.imageIndex = index;

        this.isSelected = isSelected;

        this.boxAngle = 0;
        this.isDragging = false;
        this.isScaling = false;
        this.isRotating = false;
        this.parentIndex = parentIndex;

        // 메서드 바인딩
        this.onPointerStart = this.onPointerStart.bind(this);
        this.onPointerMove = this.onPointerMove.bind(this);
        this.onPointerEnd = this.onPointerEnd.bind(this);
        this.onScaleStart = this.onScaleStart.bind(this);
        this.onScaleMove = this.onScaleMove.bind(this);
        this.onScaleEnd = this.onScaleEnd.bind(this);
        this.onRotateStart = this.onRotateStart.bind(this);
        this.onRotateMove = this.onRotateMove.bind(this);
        this.onRotateEnd = this.onRotateEnd.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);

        this.initXpos = ['66.3%', '65%', '19.3%'][this.parentIndex];

        this.init();
        // window.addEventListener('resize', this.updatePositionOnResize.bind(this));
        document.addEventListener('click', this.handleDocumentClick);
    }

    init() {
        const dragElement = document.createElement('div');
        dragElement.classList.add('draggable');
        dragElement.innerHTML = `
            <div class="drag-inner" style="padding-bottom: ${this.ratio}%">
                <img src="${this.image}" alt="">
                <button class="button-handler button-scale"></button>
                <button class="button-handler button-delete"></button>
                <button class="button-handler button-rotate"></button>
            </div>`;

        dragElement.classList.add(`i${this.parentIndex + 1}`);
        dragElement.style.zIndex = this.stickerGroup.parentCard.getNextZIndex();
        this.container.appendChild(dragElement);
        this.box = dragElement;


        this.scaleHandle = dragElement.querySelector('.button-scale');
        this.rotateHandle = dragElement.querySelector('.button-rotate');

        // delete 버튼 이벤트 추가
        dragElement.querySelector('.button-delete').addEventListener('click', (e) => {
            e.preventDefault();
            this.deleteSticker();
        });

        gsap.set(this.box, { left: `calc(${this.initXpos} + ${(this.stickerGroup.sticker.length - 1) * 10}px)`});

        const { width, height, top, left } = dragElement.getBoundingClientRect();

        // console.log('init : ',this.boxX, this.boxY)

        this.boxWidth = width - 20;
        this.boxHeight = height - 20;
        this.initialWidth = this.boxWidth;
        this.initialHeight = this.boxHeight;
        this.minWidth = this.initialWidth;
        this.maxWidth = (this.initialWidth) * 2;

        this.boxWidthPercent = gsap.getProperty(this.box, 'width', '%');
        this.boxX = left - this.card.getBoundingClientRect().left;
        this.boxY = top - this.card.getBoundingClientRect().top;
        this.updateBox();

        // 선택 기능과 이벤트 초기화
        this.box.addEventListener('click', this.handleSelection);
        this.initEvents();

        if (this.isSelected) {
            this.handleSelection()
            gsap.delayedCall(0.05, () => {
                this.box.classList.add('is-touch')
            })
        }
    }

    handleSelection(e) {
        if (e) {
            e.stopPropagation();
        }
        document.querySelectorAll('.is-touch').forEach(el => el.classList.remove('is-touch'));

        this.box.classList.add('is-touch');
    }

    handleDocumentClick(event) {
        if (!this.box.contains(event.target)) {
            this.box.classList.remove('is-touch'); // 외부 클릭 시 선택 해제
        }
    }

    initEvents() {
        // 드래그 이벤트는 선택된 상태일 때만 작동
        this.box.addEventListener('mousedown', (e) => {
            if (this.box.classList.contains('is-touch')) this.onPointerStart(e);
        });
        this.box.addEventListener('touchstart', (e) => {
            if (this.box.classList.contains('is-touch')) this.onPointerStart(e);
        });

        // 스케일 및 회전 핸들 이벤트
        this.scaleHandle.addEventListener('mousedown', this.onScaleStart);
        this.scaleHandle.addEventListener('touchstart', this.onScaleStart);
        this.rotateHandle.addEventListener('mousedown', this.onRotateStart);
        this.rotateHandle.addEventListener('touchstart', this.onRotateStart);
    }

    onPointerStart(e) {
        if (e.target.classList.contains('button-delete') || e.target.classList.contains('button-scale') || e.target.classList.contains('button-rotate')) return;
        // console.log('start : ',this.boxX, this.boxY)
        
        this.bringToFront();
        const pointer = e.touches ? e.touches[0] : e;
        e.preventDefault();

        // this.initialMouseX = pointer.clientX;
        // this.initialMouseY = pointer.clientY;

        // const boxRect = this.box.getBoundingClientRect();
        // const cardRect = this.container.getBoundingClientRect();
        this.offsetX = pointer.clientX - this.boxX;
        this.offsetY = pointer.clientY - this.boxY;



        // this.relativeX = ((this.initialMouseX - this.offsetX - cardRect.left) / cardRect.width) * 100;
        // this.relativeY = ((this.initialMouseY - this.offsetY - cardRect.top) / cardRect.height) * 100;

        // this.relativeX = this.offsetX - cardRect.left;
        // this.relativeY = this.offsetY - cardRect.top
        // this.updateBox();
        this.isDragging = true;

        document.addEventListener('mousemove', this.onPointerMove);
        document.addEventListener('mouseup', this.onPointerEnd);
        document.addEventListener('touchmove', this.onPointerMove);
        document.addEventListener('touchend', this.onPointerEnd);


    }

    onPointerMove(e) {
        if (!this.isDragging) return;

        const pointer = e.touches ? e.touches[0] : e;

        this.boxX = pointer.clientX - this.offsetX;
        this.boxY = pointer.clientY - this.offsetY;

        // const cardRect = this.card.getBoundingClientRect();
        // this.relativeX = ((this.boxX - cardRect.left) / cardRect.width) * 100;
        // this.relativeY = ((this.boxY - cardRect.top) / cardRect.height) * 100;


        this.updateBox();
    }

    onPointerEnd(e) {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.onPointerMove);
        document.removeEventListener('mouseup', this.onPointerEnd);
        document.removeEventListener('touchmove', this.onPointerMove);
        document.removeEventListener('touchend', this.onPointerEnd);
    }
    onScaleStart(e) {
        e.preventDefault();
        const pointer = e.touches ? e.touches[0] : e;
        this.offsetX = pointer.clientX;
        this.offsetY = pointer.clientY;

        this.initialWidth = this.boxWidth;
        this.initialHeight = this.boxHeight;
        this.isScaling = true;
    
        document.addEventListener('mousemove', this.onScaleMove);
        document.addEventListener('mouseup', this.onScaleEnd);
        document.addEventListener('touchmove', this.onScaleMove);
        document.addEventListener('touchend', this.onScaleEnd);
    }
    
    onScaleMove(e) {
        if (!this.isScaling) return;
    
        const pointer = e.touches ? e.touches[0] : e;
        const deltaX = pointer.clientX - this.offsetX;
        const deltaY = pointer.clientY - this.offsetY;
        
        
        const angleInRadians = (this.boxAngle * Math.PI) / 180 ;
        const adjustedDelta = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
    
        
        // // 초기 스케일 비율을 기준으로 스케일 조정
        const newWidth = Math.min(Math.max(this.minWidth, this.initialWidth + adjustedDelta), this.maxWidth);
        const aspectRatio = this.initialHeight / this.initialWidth;
       
        const newHeight = newWidth * aspectRatio;

        this.boxWidth = newWidth;
        this.boxX -= (newWidth - this.boxWidth) / 2;
        this.boxY -= (newHeight - this.boxHeight) / 2


        this.boxHeight = newHeight

        const cardRect = this.card.getBoundingClientRect();
        this.boxWidthPercent = (newWidth / cardRect.width) * 100;
        this.boxHeightPercent = (newHeight / cardRect.height) * 100;
    
        this.updateBox();
    }
    
    onScaleEnd(e) {
        this.isScaling = false;
        this.offsetX = 0;
        document.removeEventListener('mousemove', this.onScaleMove);
        document.removeEventListener('mouseup', this.onScaleEnd);
        document.removeEventListener('touchmove', this.onScaleMove);
        document.removeEventListener('touchend', this.onScaleEnd);
    }

    onRotateStart(e) {
        e.preventDefault();
        const pointer = e.touches ? e.touches[0] : e;
        const boxRect = this.box.getBoundingClientRect();
        this.centerX = boxRect.left + boxRect.width / 2;
        this.centerY = boxRect.top + boxRect.height / 2;

        this.startAngle = Math.atan2(pointer.clientY - this.centerY, pointer.clientX - this.centerX);

        this.initialRotation = this.boxAngle;
        this.isRotating = true;

        document.addEventListener('mousemove', this.onRotateMove);
        document.addEventListener('mouseup', this.onRotateEnd);
        document.addEventListener('touchmove', this.onRotateMove);
        document.addEventListener('touchend', this.onRotateEnd);
    }

    onRotateMove(e) {
        if (!this.isRotating) return;

        const pointer = e.touches ? e.touches[0] : e;
        const currentAngle = Math.atan2(pointer.clientY - this.centerY, pointer.clientX - this.centerX);
        const angleInDegrees = ((currentAngle - this.startAngle) * 180) / Math.PI;

        this.boxAngle = this.initialRotation + angleInDegrees;
        this.updateBox();
    }

    onRotateEnd(e) {
        this.isRotating = false;
        document.removeEventListener('mousemove', this.onRotateMove);
        document.removeEventListener('mouseup', this.onRotateEnd);
        document.removeEventListener('touchmove', this.onRotateMove);
        document.removeEventListener('touchend', this.onRotateEnd);
    }

    updateBox() {

        this.box.style.width = `${this.boxWidthPercent}%`;
        this.box.style.height = `${this.boxHeight}%`;
        this.box.style.left = `${this.boxX}px`;
        this.box.style.top = `${this.boxY}px`;
        this.box.style.transformOrigin = '50% 50%';
        this.box.style.transform = `rotate(${this.boxAngle}deg)`;
    }

    updatePositionOnResize() {
        const cardRect = this.card.getBoundingClientRect();
        this.boxX = cardRect.left + (this.relativeX / 100) * cardRect.width;
        this.boxY = cardRect.top + (this.relativeY / 100) * cardRect.height;
        this.updateBox();
    }

    deleteSticker() {
        this.container.removeChild(this.box);
        this.stickerGroup.notifyStickerDeleted(this.imageIndex);
    }

    bringToFront() {
        this.box.style.zIndex = this.stickerGroup.parentCard.getNextZIndex();
    }
}
