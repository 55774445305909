import gsap from "gsap";


export function clamp(value, min, max) {
    return value < min ? min : (value > max ? max : value);
}
export function openAccordion(accordion) {
    const content = accordion.querySelector(".accordion-content");
    if (accordion) {
        accordion.setAttribute("data-open", true);
    }

    const tr = accordion.hasAttribute("data-fade");

    const completeCallback = () => {
        const _closeEvent = (e) => {
            if (e.target.tagName !== "BUTTON") {
                closeAccordion(accordion);
                document.removeEventListener("click", _closeEvent);
            }
        };
        document.addEventListener("click", _closeEvent);
    };

    if (tr) {
        gsap.set(content, { display: "flex" });
        gsap.to(content, {
            autoAlpha: 1,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => completeCallback(),
        });
    } else {
        gsap.to(content, {
            height: "auto",
            duration: 0.55,
            ease: "power1.inOut",
        });
    }
    if (accordion.querySelector(".line-ver")) {
        gsap.to(accordion.querySelector(".line-ver"), {
            rotate: 90,
            duration: 0.3,
            ease: "power2.out",
        });
    }
}

export function closeAccordion(accordion) {
    const content = accordion.querySelector(".accordion-content");
    const tr = accordion.hasAttribute("data-fade");

    if (tr) {
        accordion.setAttribute("data-open", false);
        gsap.to(content, {
            autoAlpha: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
                gsap.set(content, { display: "none" });
            },
        });
    }

    if (accordion.querySelector(".line-ver")) {
        accordion.setAttribute("data-open", false);
        gsap.to(content, {
            height: 0,
            duration: 0.4,
            ease: "power1.inOut",
            onComplete: () => {},
        });
        gsap.to(accordion.querySelector(".line-ver"), {
            rotate: 0,
            duration: 0.25,
            ease: "power2.out",
        });
    }
}

export function toggleAccordion(accordion) {
    const isOpen = accordion.getAttribute("data-open") == "true";

    if (!isOpen) {
        if (document.querySelector('.accordion-container[data-open="true"]')) {
            closeAccordion(document.querySelector('.accordion-container[data-open="true"]'));
        }
        openAccordion(accordion);
    } else {
        closeAccordion(accordion);
    }
}
export function toast(msg) {
    if (msg) {
        document.querySelector(".toast span").innerHTML = msg;
    }
    gsap.to(document.querySelector(".toast"), {
        opacity: 1,
        duration: 0.4,
        ease: "power1.inOut",
        onComplete: () => {
            gsap.to(document.querySelector(".toast"), {
                opacity: 0,
                duration: 0.3,
                ease: "power1.inOut",
                delay: 1,
            });
        },
    });
}

export function copyClipboard(button) {
    if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(button.getAttribute("data-value")).then(() => {
            toast("링크가 복사되었습니다");
        });
    }
}

export function toggleClass(arr, index, _class = 'selected') {
    arr.forEach((a, i) => a.classList[i === index ? 'add' : 'remove'](_class))
}


const sheetYV = 30;
export function appearBottomSheet(sheet, _callback) {
    const content = sheet.querySelector('.bottomsheet-popup');
    gsap.set(sheet, { display: 'block' })
    gsap.to(content, {
        opacity: 1,
        duration: 0.35,
        ease: "power1.out",
        onComplete: () => {
            gsap.set(sheet, { pointerEvents: 'auto' })
        }
    });
    gsap.to(content, { y: 0, duration: 0.35, ease: "power2.out", onComplete: () => {
        if (content.querySelector('.loading')) {
            gsap.to(content.querySelector('.loading'), { opacity: 1, duration: 0.2, ease: 'power1.out' })
            document.querySelector('.loading lottie-player').play();
        }

        if (_callback) {
            _callback()
        }
    } });
}



export function disappearBottomSheet(sheet) {

    const content = sheet.querySelector('.bottomsheet-popup');

    gsap.set(sheet, { pointerEvents: 'none' })
    gsap.to(content, { opacity: 0, duration: 0.15, ease: "power2.out" });
    gsap.to(content, { y: sheetYV, duration: 0.3, ease: "power1.out" });

    gsap.set(sheet, { display: 'none', delay: 0.3 })
}

export function appearLayer(layer) {
    gsap.set(layer, { display: 'flex' })
    gsap.to(layer, {
        opacity: 1,
        duration: 0.35,
        ease: "power1.inOut"
    });

    if (layer.querySelector('.floating-message')) {
        gsap.to(layer.querySelector('.floating-message'), { 
            opacity: 1,
            duration: 0.5,
            ease: 'power1.inOut',
            delay: 0.4
        })
        gsap.to(layer.querySelector('.floating-message'), { 
            y: '0%',
            duration: 0.55,
            ease: 'power2.out',
            delay: 0.4
        })
    }
}

export function disappearLayer(layer, callback) {
    const content = layer.querySelector('.content');
    gsap.to(layer, {
        opacity: 0,
        duration: 0.35,
        ease: "power1.inOut",
        onComplete: () => {
            gsap.set(layer, { display: 'none', onComplete: () => {
                if (callback) {
                    callback()
                }
                if (layer.querySelector('.floating-message')) {
                    gsap.set(layer.querySelector('.floating-message'), { 
                        y: '20%',
                        opacity: 0
                    })
                }
            } })
        }
    });
}