import gsap from 'gsap';
import Sticker from "./Sticker";

export default class Photo extends Sticker {
    init() {
        const dragElement = document.createElement('div');
        dragElement.classList.add('draggable');
        dragElement.innerHTML = `
            <div class="drag-inner" style="padding-bottom: ${this.ratio}%">
                <img src="${this.image}" alt="">
            </div>`;

        this.card.appendChild(dragElement);
        this.box = dragElement;

        const { width, height, x, y } = dragElement.getBoundingClientRect();
        this.boxX = x;
        this.boxY = y;
        this.boxWidth = width;
        this.boxHeight = height;
        this.initialWidth = this.boxWidth - 20;
        this.minWidth = this.initialWidth

        this.onPointerMove = this.onPointerMove.bind(this);
        this.onPointerEnd = this.onPointerEnd.bind(this);

        this.container = this.card;

        this.isPointer = false;
        this.initEvents();
    }

    initEvents() {
        this.box.addEventListener('click', (e) => {
            e.preventDefault()
            if (this.isPointer) return;
            document.querySelector('.input-file').click()
        })

        if ('ontouchstart' in window) {
            this.box.addEventListener('touchstart', this.onPointerStart, { passive: true });
        } else {
            this.box.addEventListener('mousedown', this.onPointerStart);
        }

    }
    
    bringToFront() {}

    onPointerStart(e) {
        if (e.target.classList.contains('button-delete')) return;

        // e.preventDefault();

        const isTouchEvent = e.type === 'touchstart';
        const pointer = isTouchEvent ? e.touches[0] : e;

        this.initialMouseY = pointer.clientY;
        const boxRect = this.box.getBoundingClientRect();
        const cardRect = this.card.getBoundingClientRect();

        this.offsetY = this.initialMouseY - boxRect.top;

        this.isDragging = true;

        if (isTouchEvent) {
            document.addEventListener('touchmove', this.onPointerMove);
            document.addEventListener('touchend', this.onPointerEnd);
        } else {
            document.addEventListener('mousemove', this.onPointerMove);
            document.addEventListener('mouseup', this.onPointerEnd);
        }
    }

    onPointerMove(e) {
        const isTouchEvent = e.type.startsWith('touch');
        const pointer = isTouchEvent ? e.touches[0] : e;
        const currentY = pointer.clientY;

        const cardRect = this.card.getBoundingClientRect();
        const boxRect = this.box.getBoundingClientRect();

        if (this.isDragging) {
            const newY = currentY - this.offsetY - cardRect.top;

            let minYPercent, maxYPercent;

            if (boxRect.height > cardRect.height) {
                minYPercent = ((cardRect.height - boxRect.height) / cardRect.height) * 100;
                maxYPercent = 0;
            } else {
                minYPercent = 0;
                maxYPercent = ((cardRect.height - boxRect.height) / cardRect.height) * 100;
            }

            const constrainedYPercent = Math.max(minYPercent, Math.min((newY / cardRect.height) * 100, maxYPercent));

            // `top` 속성을 %로 적용하여 상하 이동하도록 설정
            gsap.set(this.box, { top: `${constrainedYPercent}%` });

        }

        this.isPointer = true;
    }

    onPointerEnd(e) {
        
        this.isDragging = true;

        const _this = this;

        setTimeout(() => {
            _this.isPointer = false;
        }, 30)
        
        document.removeEventListener('mousemove', this.onPointerMove);
        document.removeEventListener('mouseup', this.onPointerEnd);
        document.removeEventListener('touchmove', this.onPointerMove);
        document.removeEventListener('touchend', this.onPointerEnd);
    }
}
