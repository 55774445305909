import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Card from './Card';
import { toggleClass, appearBottomSheet, disappearBottomSheet, openAccordion } from './utils';
// import '/static/fonts/font.css';
import './pub_style.css';
import './fe_style.css';
import './common.css';


function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

function initWindowHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
}
window.addEventListener('resize', () => {
    initWindowHeight();
});

function resetScroll() {
    
    ScrollTrigger.clearScrollMemory();
    window.onbeforeunload = function () {
        gsap.set(window, {
            scrollTo: {
                y: 0
            }
        });
    };
    gsap.set(window, {
        scrollTo: {
            y: 0
        }
    });
}

function init() {
    ScrollTrigger.clearScrollMemory();
    gsap.config({
        nullTargetWarn: false,
        trialWarn: false
    });
    initWindowHeight();

    const isMobile = isTouchDevice();

    if (isMobile) {
        document.body.classList.add('is-mo');
    } else {
        document.body.classList.add('is-pc');
    }

    const card = new Card();
    new Project(card)
    
}
class Project {
    constructor(card) {
        this.card = card;
        this.stickers = []
        // this.canvas = new fabric.Canvas(document.querySelector('#card'));
        // this.canvas.setDimensions({
        //     width: 400,
        //     height: 600
        // })

        this.imageURL = '';
        this.webUrl = '';
        this.init()
    }

    init() {

        gsap.to(document.body, { 
            opacity: 1,
            duration: 0.4, 
            ease: 'power1.inOut'
        })
        // Kakao.init('b521948f7e50926c73783a61fec1b9b5');
        // for (let i = 1; i < 5; i++) {
        //     const _sticker = new Sticker(`/assets/sticker-0${i}.png`, this.canvas)
        //     this.stickers.push(_sticker);
        // }

        this.event_Tab()
        this.event_BottmSheet()

        // this.event_POST();
        // this.event_GET();
        // this.event_CREATE_IMAGE();
        // this.bindEvent();

        this.isAnim = false;
    }

    event_Tab() {
        const tabs = gsap.utils.toArray('.tabs-wrap .button-tab').filter(button => button.dataset.type !== 'photo')
        const sheets = gsap.utils.toArray('.bottomsheet');
        tabs.forEach((button, index) => {
            const type = button.getAttribute('data-type');
            const sheet = sheets.filter(s => type === s.getAttribute('data-type'))[0];
            button.addEventListener('click', () => {
                if (this.isAnim) return;
                this.isAnim = true;

                const _callback = () => {
                    this.isAnim = false;
                }
                appearBottomSheet(sheet, _callback)
                this.card.updateSheet(sheet, type);
                // const layer = document.querySelector(`.bottomsheet[data-type]=${button.dataset.type}`)
                toggleClass(sheets, index)

            })
        })
    }

    event_BottmSheet() {
        const sheets = gsap.utils.toArray('.bottomsheet');
        sheets.forEach((sheet) => {
            gsap.utils.toArray('.button-close', sheet).forEach((button, index) => [
                
                button.addEventListener('click', () => {
                    disappearBottomSheet(sheet);
                    // toggleClass(sheets, index)
                    // sheet.classList.remove('selected')
                })
            ])
        })
    }

    bindEvent() {
        document.querySelector('#button-share').addEventListener('click', () => {
            this.event_SHARE_KAKAO(window.location.href);
        });
    }

    async uploadImageToServer(base64Image) {
        const mimeType = base64Image.match(/^data:(.*);base64,/)[1];
        const byteString = atob(base64Image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeType });
    
        const formData = new FormData();
        formData.append('image', blob, 'image.png');
    
        
        const response = await fetch('https://image-upload-server-2c8bf94fcf58.herokuapp.com/upload', {
            method: 'POST',
            body: formData,
        });
        const result = await response.json();
        
        return result; 
    }


}

window.onload = init();