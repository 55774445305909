import gsap from 'gsap';
import Sticker from "./Sticker";
import Photo from './Photo';
import { toggleClass, disappearBottomSheet, appearBottomSheet, appearLoading, appearLayer, disappearLayer } from './utils';
import { domToJpeg } from 'modern-screenshot';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import lottie from 'lottie-web';
import animationData from './data/loading.json';



class StickerGroup {
    constructor(groupId, parentCard) {
        this.groupId = groupId;
        this.parentCard = parentCard; // Card 클래스 인스턴스를 참조
        this.sticker = [];
    }

    // 스티커 생성
    createSticker(imageSrc, ratio, index, childIndex, isSelect) {
        if (this.sticker && this.sticker.filter(s => s.imageIndex == childIndex).length > 0) {
            return;
        } else {
            const sticker = new Sticker(this.parentCard.card, imageSrc, ratio, this, index, childIndex, isSelect); // 올바르게 Card 인스턴스를 전달;
            this.sticker.push(sticker);
        }

        // if (this.sticker) {
        //     if (this.sticker.imageIndex === childIndex) {
        //         return;
        //     }
        //     this.sticker.deleteSticker()
        //     // return;
        // }
        // 새로운 스티커 생성
        

    }

    // 스티커 삭제 알림
    notifyStickerDeleted(deletedIndex) {
        // this.sticker = null; // 스티커가 삭제된 것을 그룹에서 반영
        const newValue = this.sticker.filter(s => s.imageIndex !== deletedIndex);

        this.sticker = newValue;
        this.parentCard.notifyStickerDeleted(this.groupId)
    }
}

export default class Card {
    constructor() {
        this.zIndexCounter = 1; // z-index를 관리할 변수
        
        this.assetLength = 3; // 그룹 개수
        this.assetChildLength = 4; // 각 그룹에 포함된 스티커 개수

        this.card = document.querySelector('#card');
        this.stickerContainer = document.querySelectorAll('.bottomsheet[data-type="sticker"] .row')
        this.fontButtons = gsap.utils.toArray('.bottomsheet[data-type="text"] .button-item');

        this.loadingLayer = document.querySelector('.layer')
        this.loading = this.loadingLayer.querySelector('.loading')
        this.resultLayer = this.loadingLayer.querySelector('.result-layer');
        // textarea 추가
        this.textArea = this.card.querySelector('#main-textarea');
        this.textArea.value = '';

        this.t_value = '';

        this.stickerGroups = [[], [], []];
        this.selectedFontIndex = 0;
        this.selectedStickerIndex = [[],[],[]];
        
        this.init();
    }

    init() {

        // 사용자 이미지 업로드를 위한 파일 입력 추가
        this.addImageUpload();
        this.loadFont(); // 폰트 로드 호출
        this.loadSticker();

        // 이미지 저장 버튼 추가
        this.addSaveButton();

        this.event_Attach()



        this.initLoading()

        this.initTextInput()
    }
    notifyStickerDeleted(groupIndex, stickerIndex) {
        // this.selectedStickerIndex[groupIndex][stickerIndex] = null;
    }

    preCheckTextInput() {
        const current = this.textArea.value;
        const next = document.querySelector('.hidden-textarea');
        next.value = '';
        let _value = '';

        for (let i = 0; i < current.length; i++) {
            next.value += current.charAt(i);
            _value = next.value;
    
            if ((next.scrollHeight - next.clientHeight) >= 7) {
                next.value = _value;
                this.textArea.value = _value.substring(0, _value.length - 1);
                this.t_value = this.textArea.value;

                return;
            }
        }
    }
    checkTextInput() {
        const maxLines = 2;
        const textarea = this.textArea;
     
        const preventTextInput = () => {
            this.textArea.classList.add('disabled')
            this.t_value = textarea.value;
        }
        // 현재 텍스트에서 줄바꿈을 기준으로 줄 수 체크
        const lines = textarea.value.split('\n');

        if (lines.length > maxLines) {
            // 두 줄을 넘는 입력이 발생하면 잘라내기
            this.textArea.value = lines.slice(0, maxLines).join('\n');
            preventTextInput()
        }


        if ((this.textArea.scrollHeight - this.textArea.clientHeight) >= 7) {
            this.preCheckTextInput()

            if (this.textArea.classList.contains('disabled')) {
                this.textArea.value = this.t_value;
            } else {
                this.textArea.value = this.t_value.substring(0, this.t_value.length - 1);
                this.preCheckTextInput()
                preventTextInput()
            }
            return;
        } else {
            this.t_value = this.textArea.value;
            if (this.textArea.classList.contains('disabled')) {
                this.textArea.classList.remove('disabled')
                this.textArea.value = this.t_value;
            }
        }
        
    }
    initTextInput() {
        this.textArea.addEventListener('input', (e) => {
            this.checkTextInput()
        });
        this.textArea.onfocus = () => {
            this.checkTextInput()
        }
    }


    event_Attach() {
        gsap.utils.toArray('.button-submit').forEach(button => {
            const type = button.getAttribute('data-type');
            const sheet = document.querySelector(`.bottomsheet[data-type="${type}"]`);

            button.addEventListener('click', () => {
                disappearBottomSheet(sheet);
                this[`update_${type}`]();
            })
        })
    }

    // 폰트 로드 메서드
    loadFont() {
        const _this = this;

        // WebFont.load({
        //     custom: {
        //         families: ['Pretendard', 'Hahmlet', 'HakgyoansimBadasseugi', 'YClover', 'NeoDunggeunmoPro', 'NanumMyeongjo'], // 로컬 폰트 이름
        //         urls: ['/static/assets/fonts/font.css'] 
        //     },
        //     active: function() {
        //         console.log('로컬 폰트가 성공적으로 로드되었습니다.');
        //         // _this.createFontButtons(this.custom.families); // 폰트 버튼 생성
        //     },
        //     inactive: function() {
        //         console.log('폰트 로딩에 실패했습니다.');
        //     }
        // });
        // // 구글 폰트
        // WebFont.load({
        //     google: {
        //         families: ['Arima', 'Playwrite GB S', 'Droid Sans', 'Droid Serif']
        //     },
        //     active: function() {
        //         console.log('폰트가 성공적으로 로드되었습니다.');
        //         _this.createFontButtons(this.google.families); // 폰트 버튼 생성
        //     },
        //     inactive: function() {
        //         console.log('폰트 로딩에 실패했습니다.');
        //     }
        // });

        this.fontButtons.forEach((button, index) => {
            button.addEventListener('click', () => {
                toggleClass(this.fontButtons, index);
            })
        })

    }

    updateSheet(sheet, type) {
        if (type === 'sticker') {
            let _count = 0;
            this.stickerGroups.forEach((group, i) => {
                const _stickerIndex = group.sticker.map(s => s.imageIndex);
                gsap.utils.toArray('.button-item', this.stickerContainer[i]).forEach((s, j) => {
                    s.classList[_stickerIndex.includes(j) ? 'add' : 'remove']('selected');
                    if (_stickerIndex.includes(j)) {
                        s.classList.add('selected')
                        s.setAttribute('data-selected', _count);
                        _count++;
                    } else {
                        s.classList.remove('selected');
                        s.removeAttribute('data-selected')
                    }
                })
            })
        } else if (type === 'text') {
            toggleClass(this.fontButtons, this.selectedFontIndex)
        }
    }

    update_sticker() {
        let _count = gsap.utils.toArray('.bottomsheet[data-type="sticker"] .button-item.selected').length;
        this.stickerContainer.forEach((container, index) => {
            const buttons = gsap.utils.toArray('.button-item', container);
            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                if (button.classList.contains('selected')) {
                    let isLast = false;
                    _count--;
                    if (_count == 0) {
                        isLast = true;
                    }
                    const image = button.querySelector('img');
                    this.stickerGroups[index].createSticker(image.src, button.getAttribute('data-ratio'), index, i, isLast);
                    
                } else {
                    if (this.stickerGroups[index].sticker && this.stickerGroups[index].sticker.length > 0) {
                        const _stickerIndex = this.stickerGroups[index].sticker.map(s => s.imageIndex);
                        if (_stickerIndex.includes(i)) {
                            const _target = this.stickerGroups[index].sticker.filter(s => s.imageIndex === i)[0];
                         
                            if (_target) {
                                _target.deleteSticker(i)
                            }
                        }
                    }
                }
            }
        })
    }

    update_text() {
        this.fontButtons.forEach((button, index) => {
            if (button.classList.contains('selected')) {
                this.selectedFontIndex = index;
                gsap.utils.toArray('textarea').forEach(t => {
                    t.setAttribute('data-font', button.getAttribute('data-font'))
                })
                
                this.preCheckTextInput()
                // this.checkTextInput()
            }
        })
    }
    
    loadSticker() {
        for (let i = 0; i < this.stickerContainer.length; i++) {
            const group = new StickerGroup(i, this); // Card 인스턴스를 전달
            this.stickerGroups[i] = group;

            const container = this.stickerContainer[i];
            const buttons = gsap.utils.toArray('.button-item', container);

            buttons.forEach((button, index) => {
                const image = new Image()
                image.classList.add('hidden-image')
                image.src = button.querySelector('img').src;

                
                image.onload = () => {
                    const { naturalWidth, naturalHeight } = image;
                    const ratio = (naturalHeight / naturalWidth) * 100;

                    button.setAttribute('data-ratio', ratio.toFixed(2));
                    const _buttons = () => buttons.filter(b => b.classList.contains('selected'))
                    // 스티커 생성 버튼 클릭 시
                    button.addEventListener('click', () => {
                        if (button.classList.contains('selected')) {
                            button.classList.remove('selected')
                            button.removeAttribute('data-selected')
                            _buttons().forEach(b => {
                                const _num = b.getAttribute('data-selected');
                                const _next = _num - 1;
                                b.setAttribute('data-selected', _next < 0 ? 0 : _next)
                            })
                        } else {
                            button.setAttribute('data-selected', _buttons().length)
                            button.classList.add('selected')
                        }
                    });
                };
            })
        }
        
    }

    addImageUpload() {
        const fileInputs = gsap.utils.toArray('.input-file')
        fileInputs.forEach(fileInput => {
            fileInput.addEventListener('change', (event) => {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (document.querySelector('.photo-thumb .draggable')) {
                            document.querySelector('.photo-thumb .draggable').remove()
                        }
    
                        const image = new Image();
                        image.src = e.target.result;
                        image.onload = () => {
                            const { naturalWidth, naturalHeight } = image;
                            const ratio = (naturalHeight / naturalWidth) * 100;
                            if (this.card.querySelector('.photo-thumb img')) {
                                this.card.querySelector('.photo-thumb img').remove()
                            }
                            new Photo(this.card.querySelector('.photo-thumb'), image.src, ratio, null, null, null)
                        }
                    };
                    reader.readAsDataURL(file);
                }
            });
        })



        // this.card.appendChild(fileInput); // 카드에 파일 입력 추가
    }
    // z-index 최상단으로 설정하기 위한 메서드
    getNextZIndex() {
        return this.zIndexCounter++; // z-index 값 증가
    }

    // 이미지 저장 버튼 추가 및 새 창에 띄우기
    addSaveButton() {

        // 이미지 저장 이벤트
        const buttonSave = document.querySelector('.button-save');
        const buttonClose = document.querySelector('.button-close-layer');
        buttonSave.addEventListener('click', () => {
            buttonSave.disabled = true;
            this.showCardAsImage();
            gsap.utils.toArray('.is-touch').forEach(t => t.classList.remove('is-touch'))
            document.activeElement.blur()
        });
        
        buttonClose.addEventListener('click', () => {
            buttonClose.disabled = true;
            const callback = () => {
                gsap.set(this.resultLayer, { opacity: 0 })
                this.resultLayer.querySelector('.content img').remove()
                buttonSave.disabled = false;
                buttonClose.disabled = false;
            }

            disappearLayer(this.loadingLayer, callback)
        })
    }

    

    // card 요소를 이미지로 캡처하고 새 창에 띄우는 메서드
    showCardAsImage() {
        this.loadingPlay()

        document.body.classList.add('is-saving');

        let _scale = document.body.classList.contains('is-mo') ? 3 : 2;
        let _quality = document.body.classList.contains('is-mo') ? 2 : 3;

        gsap.delayedCall(0.8, () => {
            domToJpeg(this.card, {
                scale: _scale,
                quality: _quality,
                features: {
                    removeControlCharacter: false
                }
            }).then((dataUrl) => {
                document.body.classList.remove('is-saving');
                const image = new Image()
                image.src = dataUrl;
    
                image.onload = () => {
                    this.resultLayer.querySelector('.content').appendChild(image);
    
                    const _callback = () => {
                        appearLayer(this.resultLayer);
                    }
                    this.loadingPause(_callback)
                }
            });
        })
    }
    

    initLoading() {
        // LottiePlayer 초기화
        this.loadingAnim = lottie.loadAnimation({
            container: this.loadingLayer.querySelector('.loading'), // <div> 요소를 컨테이너로 설정
            renderer: 'svg', // SVG 렌더러를 사용하여 품질을 높임
            loop: true,
            autoplay: false,
            animationData: animationData,
        });

        // gsap.delayedCall(1, () => {
        //     this.loadingPlay()
        // })
    }

    loadingPlay(callback) {
        if (this.loadingAnim.isPaused) {
            this.loadingAnim.play()
        }

        gsap.set(this.loadingLayer, { display: 'block' })
        gsap.to(this.loadingLayer, {
            opacity: 1,
            duration: 0.3,
            ease: "power1.inOut",
        });
        gsap.to(this.loading, {
            opacity: 1,
            duration: 0.4,
            ease: "power1.inOut"
        });

    }

    loadingPause(callback) {
        // gsap.to(this.loadingLayer, {
        //     opacity: 0,
        //     duration: 0.3,
        //     ease: "power1.inOut"
        // });
        gsap.to(this.loading, { opacity: 0, duration: 0.25, ease: 'power1.inOut', onComplete: () => {
            if (!this.loadingAnim.isPaused) {
                this.loadingAnim.pause()
                this.loadingAnim.currentFrame = 0;
            }
            if (callback) {
                callback()
            }
        } })
    }
}
